<script lang="ts">
  import classnames from 'classnames';
  import { omit } from '$lib/utils/object';

  export let fluid = false;
  export let noGrid = false;
  export let fullHeight = false;
  export let noHorizontalPadding = false;
  export let noHorizontalGap = false;
  export let noVerticalGap = false;
  export let disabled = false;

  const attributes = omit($$restProps, 'class');
</script>

<div
  class:disabled
  class={classnames('container', $$props.class, {
    fluid: fluid,
    'no-x-padding': noHorizontalPadding,
    'no-x-gap': noHorizontalGap,
    'no-y-gap': noVerticalGap,
    'no-grid': noGrid,
    'full-height': fullHeight,
  })}
  {...attributes}
>
  <slot />
</div>

<style type="text/scss" lang="scss" scoped>
  .container {
    @apply page-margin page-max-width;
    @apply w-full;

    &.disabled {
      @apply opacity-50;
      @apply pointer-events-none;
      @apply cursor-default;
    }
    &.full-height {
      @apply h-full;
    }

    &:not(.no-grid) {
      @apply grid grid-cols-default;
      @apply gap-grid-default;
    }

    &.fluid {
      @apply max-w-none;
    }
    &.no-x-gap {
      @apply gap-x-0;
    }
    &.no-x-padding {
      @apply px-0;
    }
    &.no-y-gap {
      @apply gap-y-0 py-0;
    }
  }
</style>
